import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { BedDouble, ChevronRight, Ruler } from 'lucide-react';
import Footer from './Footer';
import { Property } from '../types/overviewTypes';

const xClientId = process.env.REACT_APP_X_CLIENT_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;
const serverId = process.env.REACT_APP_SERVER_ID;
const apiKey = process.env.REACT_APP_API_KEY;



const SkeletonLoader: React.FC = () => {
  return (<div className="animate-pulse">
    <div className="bg-gray-300 h-64 w-full rounded-lg mb-4"></div>
    <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
    <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
    <div className="h-10 bg-gray-300 rounded w-full"></div>
  </div>)
};


const fetchProperties = async (): Promise<Property[]> => {
  const url = 'https://gateway-cmsapi.v2.zabun.be/api/v1/property/search?extended=true';

  const headers = {
    'X-CLIENT-ID': xClientId,
    'client_id': clientId,
    'server_id': serverId,
    'api_key': apiKey,
    'extended': true
  };

  try {
    const response = await axios.post(url, {}, { headers });
    console.log("Response Data:", response.data.properties);
    return response.data.properties; // Direct return van properties
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to fetch properties");
  }
};

export function cleanCityName(city: string) {
  return city.replace(/\s?\(\d+\)/, '').trim();
}

export const daysSinceCreation = (creationDate: string) => {
  const currentDate = new Date();
  const creation = new Date(creationDate);

  const timeDifference = currentDate.getTime() - creation.getTime();

  const daysDifference = timeDifference / (1000 * 3600 * 24);

  return daysDifference;
};

const PropertyList: React.FC = () => {
  const [properties, setProperties] = useState<Property[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const debouncedFetchProperties = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        const data = await fetchProperties();
        setProperties(data);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchProperties();
  }, [debouncedFetchProperties]);




  if (loading)
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 mx-24 mb-10">
        {[...Array(6)].map((_, idx) => (
          <SkeletonLoader key={idx} />
        ))}
      </div>
    );




  if (error) return <div>Error: {error}</div>;
  if (properties && properties.length === 0) return <div>No properties found...</div>;

  return (

    <> <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 mx-24 mb-10" >
      {properties
        ?.filter((property) => !!property.photo_url)  // Filteren op foto's
        .sort((a, b) => new Date(b.creation).getTime() - new Date(a.creation).getTime())  // Sorteren op creation in aflopende volgorde
        .map((property) => (

          <div
            key={property.property_id}
            className="relative bg-white shadow-lg rounded-lg overflow-hidden hover:cursor-pointer group"
            onClick={() => {
              window.location.href = `/overzicht/${property.property_id}`;
            }}
          >
            {daysSinceCreation(property.creation) < 30 && (
              <div className="absolute top-4 left-4 z-10 bg-orange-400 text-white px-4 py-1 text-sm font-semibold rounded-full">
                NIEUW
              </div>
            )}
            <div className="w-full h-64 overflow-hidden">
              <img
                src={property.photo_url}
                alt={`Property ${property.reference}`}
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                loading="lazy"
              />
            </div>

            <div className="p-0">

              <div className="grid grid-cols-[1.5fr_2fr]">


                <div className='p-1'>
                  <p className="text-md font-bold text-sky-900 py-1 !mt-0">
                    € {property.price.toLocaleString()},-
                  </p>
                </div>

                <div className='grid grid-cols-[auto_auto_1fr_1fr] bg-orange-400 gap-x-1 px-1'>

                  <div className="flex items-center text-white font-bold text-md space-x-2 pl-2">
                    <BedDouble className="w-4 h-4 " />
                    <span className="text-lg font-semibold leading-tight">{property.bedroom_count}</span>

                  </div>

                  <div className="flex items-center  text-white font-bold text-md space-x-1">
                    <Ruler className="w-4 h-4" />
                    <span className="text-lg font-semibold leading-tight">{property.area_build} m²</span>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className='bg-orange-100 p-2 pt-2'>
                <p className="text-lg font-bold text-gray-800 !mt-0">
                  {property.address.city_geo.zip.toUpperCase()}  {cleanCityName(property.address.city_geo.city).toUpperCase()}
                </p>
                <p className="text-lg text-gray-700 !mt-0">
                  {property.address.street} {property.address.number}
                </p>
              </div>
              <div className="absolute bottom-4 right-4 bg-orange-400 text-white p-2 rounded-full cursor-pointer hover:bg-orange-500">
                <ChevronRight className="w-6 h-6" />
              </div>
            </div>
          </div>
        ))}
    </div>
      <Footer />
    </>
  );
};

export default PropertyList;
