import { Facebook, Instagram } from "lucide-react";

const Footer: React.FC = () => {
  return (
    <div
      className="text-white text-sm p-6 md:h-[16rem]"
      style={{ backgroundColor: "#D75202"}}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center h-full ">
        {/* Left Section */}
        <div className='grid grid-cols-3 order-2 md:order-1'>
          <div></div>
          <div className="text-center md:text-left">
            <p className="m-0">All rights reserved.</p>
            <p className="m-0" >Website by <a className="font-bold" href="https://www.vagence.be/">Vagence</a></p>
            <p className="m-0">© 2024 Vastgoed De Zutter.</p>
            <p className="m-0">BIV: 516.269</p>
            <p className="m-0">VLABELNR.: 005239619492</p>
          </div>
          <div></div>
        </div>

        {/* Right Section */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center md:text-left order-1 md:order-2">
          <div className="space-y-1">
            <p><a href="https://vastgoeddezutter.be/">Home</a></p>
            <p><a href="https://vastgoeddezutter.be/aanbod/">Aanbod</a></p>
            <p><a href="https://vastgoeddezutter.be/over-ons/">Over ons</a></p>
            <p><a href="https://vastgoeddezutter.be/contact/">Contact</a></p>
            <p><a href="https://vastgoeddezutter.be/ervaringen/">Ervaringen</a></p>
            <p><a href="https://vastgoeddezutter.be/commissie/">Commissie</a></p>
          </div>

          <div className="space-y-1">
            <p>Markt 67</p>
            <p>9900 Eeklo</p>
            <p>&ndash;</p>
            <p>D’haenestraat 18</p>
            <p>9070 Heusden</p>
          </div>

          <div className="space-y-1">
            <p> <a href="mailto:info@vastgoeddezutter.be">info@vastgoeddezutter.be</a></p>
            <p><a href="tel:+32474708353">+32 474 70 83 53</a></p>
            <p>&ndash;</p>
            <p>BE0776.458.670</p>

            <div className="flex justify-center md:justify-start space-x-4 mt-2">
              <a href="#" className="hover:underline">
                <Facebook className="h-6 w-6" />
              </a>
              <a href="https://www.instagram.com/vastgoeddezutter/" className="hover:underline">
                <Instagram className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
