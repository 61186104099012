import React, { useEffect, useState } from "react";
import axios from "axios";
import { DetailProperty } from "../types/detailTypes";
import { Bed, Bath, Ruler, Calendar, Zap, ChevronLeft, ChevronRight } from "lucide-react";
import { cleanCityName, daysSinceCreation } from "./OverviewPage";
import Footer from "./Footer";
import { getBuildingType, getEpcLabel } from "../utils/PropertyUtils";



// Function to fetch property details (you already have this part implemented)
const fetchPropertyDetails = async (id: string): Promise<DetailProperty> => {
    const url = `https://public.api-cms.zabun.be/api/v1/property/${id}?extended=true`;
    // Include headers setup
    const headers = {
        "X-CLIENT-ID": process.env.REACT_APP_X_CLIENT_ID,
        client_id: process.env.REACT_APP_CLIENT_ID,
        server_id: process.env.REACT_APP_SERVER_ID,
        api_key: process.env.REACT_APP_API_KEY,
    };

    const response = await axios.get(url, { headers });
    return response.data;
};

// Slider Component
const ImageSlider: React.FC<{ photos: { url: string }[], creation: string }> = ({ photos, creation }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const showNewBadge = daysSinceCreation(creation) < 30;

    const changeSlide = (newIndex: number) => {
        setFade(false); 
        setTimeout(() => {
          setCurrentIndex(newIndex);
          setFade(true); 
        }, 200);
    };
    
    const prevSlide = () => {
        const newIndex = currentIndex === 0 ? photos.length - 1 : currentIndex - 1;
        changeSlide(newIndex);
    };
    
    const nextSlide = () => {
        const newIndex = currentIndex === photos.length - 1 ? 0 : currentIndex + 1;
        changeSlide(newIndex);
    };

    return (
        <div className="relative w-full p-2">

            {/* Separate "NIEUW" Badge Layer */}
            {showNewBadge && (
                <div className="absolute top-4 left-4 z-10 bg-orange-400 text-white px-4 py-1 text-sm font-semibold rounded-full">
                    NIEUW
                </div>
            )}

            {/* Image Container */}
            <div className="relative">
                {photos.length > 0 ? (
                    <img
                        src={photos[currentIndex].url}
                        alt={`Slide ${currentIndex + 1}`}
                        className={`w-full h-full object-cover rounded-lg transition-opacity duration-700 ease-in-out ${
                            fade ? "opacity-100" : "opacity-0"
                        }`}
                    />
                ) : (
                    <p className="text-center text-gray-500">Geen afbeeldingen beschikbaar</p>
                )}
            </div>

            {/* Navigation Buttons */}
            {photos.length > 1 && (
                <>
                    <button
                        onClick={prevSlide}
                        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-10 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-opacity-30"
                    >
                       <ChevronLeft/>
                    </button>
                    <button
                        onClick={nextSlide}
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-10 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-opacity-30"
                    >
                        <ChevronRight/>
                    </button>
                </>
            )}

            {/* Indicator Dots */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {photos.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`w-2 h-2 rounded-full ${currentIndex === index ? "bg-white" : "bg-gray-500"}`}
                    />
                ))}
            </div>
        </div>
    );
};

const removeBrTags = (text: string): string => {
    return text.replace(/<br\s*\/?>/g, '\n'); // Vervangt <br /> of <br> met \n
};


const DetailPage: React.FC<{
    propertyId: string;
}> = ({ propertyId }) => {
    const [propertyDetails, setPropertyDetails] = useState<DetailProperty | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        const getPropertyDetails = async () => {
            try {
                const details = await fetchPropertyDetails(propertyId);
                setPropertyDetails(details);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch property details");
                setLoading(false);
            }
        };

        getPropertyDetails();
    }, []);

    useEffect(() => {
        if (propertyDetails?.photos?.length) {
            propertyDetails.photos.forEach(photo => {
                const img = new Image();
                img.src = photo.url;
            });
        }
    }, [propertyDetails]); 

    if (loading) return <div className="text-center py-10">Loading...</div>;
    if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
    if (!propertyDetails) return null;

    const {
        photos = [],
        price,
        title,
        address,
        area_ground,
        area_build,
        description,
        epc_value,
        renovation_year,
        construction_year,
        type_id,
        creation,
        epc_reference,
        available,
        heritage_inventoried_id,
        heritage_protected_ynu,
        certificate_electricity_conform

    } = propertyDetails;

   
    

    return (
        <>
            <div className="max-w-6xl mx-auto bg-white  overflow-hidden mb-10">
                {/* Header Section */}
                <div className="border-gray-300 border-b py-4 px-6 mb-4" >
                    <p className="text-2xl font-bold text-orange-950">{title?.nl || "Eigendom te koop"}</p>
                    <p className="text-xl font-semibold text-gray-700 mt-1">{`€ ${price?.toLocaleString()}`}</p>
                    <p className="text-gray-600 mt-1">
                        {`${address.street} ${address.number},
                    ${address?.city_geo?.zip.toUpperCase()}  
                    ${cleanCityName(address?.city_geo?.city || "").toUpperCase()}`}
                    </p>
                </div>


                {/* Image and properties Section */}
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <ImageSlider photos={photos} creation={creation} />
                    <div className="p-4">

                        <div className="mb-8">
                            <p className="text-xl font-semibold mb-4">Eigenschappen</p>
                            <div className="flex items-center mb-2 text-gray-700">
                                <Bed className="w-6 h-6 text-sky-800 mr-2" />
                                <span>Slaapkamers: 3</span>
                            </div>
                            <div className="flex items-center mb-2 text-gray-700">
                                <Bath className="w-6 h-6 text-sky-800 mr-2" />
                                <span>Badkamers: 1</span>
                            </div>
                            <div className="flex items-center mb-2 text-gray-700">
                                <Ruler className="w-6 h-6 text-sky-800 mr-2" />
                                <span>{`Bewoonbare oppervlakte: ${area_build || "Onbekend"} m²`}</span>
                            </div>
                            <div className="flex items-center mb-2 text-gray-700">
                                <Calendar className="w-6 h-6 text-sky-800 mr-2" />
                                <span>{`Bouwjaar: ${construction_year || "Onbekend"}`}</span>
                            </div>
                            <div className="flex items-center text-gray-700">
                                <Zap className="w-6 h-6 text-sky-800 mr-2" />
                                <span>{`EPC Waarde: ${epc_value || "Onbekend"} kWh/ m²`} {`( EPC ${getEpcLabel(epc_value)})`}</span>
                            </div>
                        </div>
                        {/* Contact Section */}
                        <div className="p-4 border-t border-gray-300">
                            <p className="text-xl font-semibold mb-2">Interesse? Neem contact op!</p>
                            <div className="flex space-x-4">
                                <button className="bg-orange-400 text-white px-6 py-2 rounded-lg hover:bg-orange-500">
                                    Boek een bezoek
                                </button>
                                <button className="bg-gray-200 text-gray-700 px-6 py-2 rounded-lg hover:bg-gray-300">
                                    Neem contact op
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-4 p-1">
                    <p className="text-md font-bold text-orange-950">{title?.nl || "Eigendom te koop"}</p>
                    <span>{removeBrTags(description.nl)}</span>
                </div>

                <div className="bg-slate-100 p-6 rounded-lg font-sans">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-sm">
                        
                        {/* Row 1 */}
                        <div className="font-bold">Bouwtype</div>
                        <div>{getBuildingType(type_id)}</div>
                        <div className="font-bold">Renovatiejaar</div>
                        <div>  {`${renovation_year || "Onbekend"}`}</div>

                        {/* Row 2 */}
                        <div className="font-bold">Totale oppervlakte</div>
                        <div>{area_ground} m²</div>
                        <div className="font-bold">Bewoonbare oppervlakte</div>
                        <div>{area_build} m²</div>

                        {/* Row 3 */}
                        <div className="font-bold">Elektriciteitscertificaat</div>
                        <div>{!certificate_electricity_conform ? "Niet conform" : "Conform" }  </div>
                        <div className="font-bold">EPC</div>
                        <div>{`EPC ${getEpcLabel(epc_value)}`}</div>

                        {/* Row 4 */}
                        <div className="font-bold">EPC referentie
                        </div>
                        <div>{epc_reference}</div>
                        <div className="font-bold">Beschikbaar</div>
                        <div>{available.nl}</div>

                        {/* Row 5 */}
                        <div className="font-bold">Beschermd erfgoed</div>
                        <div>{heritage_protected_ynu === "NO" ? "nee" : "ja"}</div>
                        <div className="font-bold">Geïnventariseerd erfgoed</div>
                        <div>{heritage_inventoried_id === 0 ? "nee" : "ja"}</div>
                    
                    </div>
                </div>

            </div>
            <Footer />
        </>


    );
};

export default DetailPage;

