export const getBuildingType = (id: number): string => {
    switch (id) {
        case 1: return "Bungalow";
        case 2: return "Chalet";
        case 3: return "Kasteel";
        case 4: return "Boerderij";
        case 5: return "Hoeve";
        case 6: return "Bel-etage";
        case 7: return "Herenhuis";
        case 8: return "Villa";
        case 9: return "Kantoorruimte";
        case 10: return "Bedrijfsgebouw";
        case 11: return "Handelspand";
        case 12: return "Appartement";
        case 13: return "Duplex";
        case 14: return "Loft";
        case 15: return "Penthouse";
        case 16: return "Studio";
        case 17: return "Bosgrond";
        case 18: return "Recreatiegrond";
        case 19: return "Landbouwgrond";
        case 20: return "Garage";
        case 21: return "Project";
        case 22: return "Magazijn";
        case 23: return "Praktijkruimte";
        case 24: return "Bouwgrond";
        case 25: return "Fermette";
        case 26: return "Huis";
        case 1721: return "Assistentiewoning";
        case 1975: return "Parking";
        case 1976: return "Staanplaats";
        case 1977: return "Hoekwoning";
        case 1978: return "Rijwoning";
        case 1979: return "Opslagplaats";
        default: return "Onbekend gebouw type";
    }
};

export const getEpcLabel = (kwh: number) => {
    if (kwh <= 100) {
        return "A";
    } else if (kwh <= 150) {
        return "B";
    } else if (kwh <= 200) {
        return "C";
    } else if (kwh <= 250) {
        return "D";
    } else if (kwh <= 300) {
        return "E";
    } else if (kwh <= 350) {
        return "F";
    } else {
        return "G";
    }
};

